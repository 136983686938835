


























































































import { checkoutRepo } from "@/repositories/CheckoutRepository";
import { IUser, userRepo } from "@/repositories/UserRepository";
import Vue from "vue";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "requestPassword",
  data() {
    return {
      form: {
        customerAddressId: 0,
        name: "",
        phone: "",
        street: "",
        number: "",
        bus: "",
        postalcode: "",
        city: "",
      },
      isLoading: false,
      submitted: false,
      user: null as IUser | null,
    };
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        phone: {
          required
        },
        street: {
          required
        },
        number: {
          required
        },
        postalcode: {
          required
        },
        city: {
          required
        }
      },
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }

      //@ts-ignore
      var savedAddress = await checkoutRepo.saveAddress(this.form);      
      if (savedAddress.success){
        var savedDeliveryInfo = await checkoutRepo.saveCustomDeliveryInfo(savedAddress.id);
        if (savedDeliveryInfo){
          this.submitted = true;
          this.$v.$reset();

          this.isLoading = false;

          this.$router.push({name: "checkout-remarks"});
        }
      }
    },
  },
  mounted: async function () {
    this.user = await userRepo.getLoggedInUserDetails();
    if (this.user != null) {
      this.form.customerAddressId = this.user.customerAddress.addressId
    }
  }
});
