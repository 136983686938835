














































































import Vue from "vue";
import { IBasket, checkoutRepo } from "@/repositories/CheckoutRepository";
import { IUser, userRepo } from "@/repositories/UserRepository";
export default Vue.extend({
  name: "checkout-overview",
  components: {

  },
  data: function () {
    return {
      user: null as IUser | null,
      basket: null as IBasket | null,
      deliveryOption: "",
      pickupLocation: null as any | null,
      deliveryPoint: null as any | null,
      facturationAddress: null as any | null,
      remark: "",
      customRef: ""
    };
  },
  mounted: async function () {
    this.user = await userRepo.getLoggedInUserDetails();
    if (this.user != null) {
      this.basket = await checkoutRepo.getCurrentBasket(this.user);
    }

    var selectedInfo = await checkoutRepo.getSelectedDeliveryInfo();
    if (selectedInfo.success) {   
      this.deliveryOption = (selectedInfo.pickup ? "pickup" : "delivery");
      this.pickupLocation = selectedInfo.pickupLocation;
      if (selectedInfo.deliveryAddress != undefined) {
        this.deliveryPoint = selectedInfo.deliveryAddress;
      }
      if (selectedInfo.facturationAddress != undefined) {
        this.facturationAddress = selectedInfo.facturationAddress;
      }
      this.remark = selectedInfo.remark;
    }
  },
  methods: {
    ConfirmOrder: async function () {
      var confirmedOrder = await checkoutRepo.confirmOrder(this.customRef);
      if (confirmedOrder){
        this.$router.push({name: "checkout-confirmation"});
      }
    },
    getOfficeLocation: function(name: string){
      if (name == "ROSSEEL"){
        return "Tielt"
      }else if (name == "HEMERYCK"){
        return "Ruddervoorde"
      }
    }
  }
});
