<template>
  <footer>
    <div class="inner-footer">
      <nav class="footer-nav">
        <ul>
          <li>
            <router-link :to="{name: 'home'}">
              <font-awesome-icon icon="home" />
              Home
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'myaccount-order-history'}">
              <font-awesome-icon icon="clipboard-list" />
              {{$t("navbarHistory")}}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'shopping-cart'}">              
              <div class="cart-icon-container">
                <font-awesome-icon icon="shopping-cart" />
                {{$t("navbarBasket")}}
                <span class="cart-counter">{{ basket_count }}</span>
              </div>              
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>  
</template>

<script>
import { checkoutRepo } from "@/repositories/CheckoutRepository";
import { userRepo } from "@/repositories/UserRepository";
import { EventBus } from "@/eventBus";

export default {
  name: "footerComponent",
  data: function () {
    return {
      basket_count: 0
    };
  },
  mounted: async function () {
    this.user = await userRepo.getLoggedInUserDetails();
    if (this.user != null) {
      this.updateBasketCount();
      
      EventBus.$on('basket-updated', this.updateBasketCount);
    }
  },
  methods: {
    async updateBasketCount() {
      this.basket_count = await checkoutRepo.getBasketCount();
    }
  },
  beforeDestroy() {
    EventBus.$off('basket-updated', this.updateBasketCount);
  }
}
</script>